import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AutomationIconService } from '../../services/automation-icon.service';

@Component({
  selector: 'app-automation-flow-button',
  templateUrl: './automation-flow-button.component.html',
  styleUrls: ['./automation-flow-button.component.scss']
})
export class AutomationFlowButtonComponent {

  constructor(private colorService: AutomationIconService, private navigate: Router) {}

  changeColor() {

    const currentStatus = this.colorService.automationStatus();

    if(currentStatus) {

      this.colorService.automationDisabled();
    } 
    else {

      this.colorService.automationEnabled();
    }
  }

  get currentColor() {
    
    return this.colorService.getColor();
  }

  openAutomationFlow() {

    if(this.currentColor === this.colorService.WHITE) {

      this.navigate.navigate(['administration/automation-flow']);
    }
  }
}
