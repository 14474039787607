import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AutomationIconService {

  private svgColor: string = '#ffffff'; // default color
  private status: boolean = false;
  public WHITE = '#ffffff';
  public RED = '#ff0000';

  getColor(): string {

    return this.svgColor;
  }

  setColor(color: string): void {

    this.svgColor = color;
  }

  automationStatus(): boolean {
      
    return this.status;
  }

  automationEnabled() {

    this.svgColor = this.RED;
    this.status = true;
  }

  automationDisabled() {

    this.svgColor = this.WHITE;
    this.status = false;
  }
}
