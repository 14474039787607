import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { initialize, LDClient, LDFlagSet, LDUser } from 'launchdarkly-js-client-sdk';
import { EnvService } from 'src/app/core/services/env.service';
import { AuthService } from 'src/app/core/services/auth-service.service';
import { map } from 'rxjs/operators';

// #region Constants

const DEFAULT_FLAG_VALUE = false;
const CHANGE_EVENT = 'change';

// #endregion

@Injectable({
    providedIn: 'root'
})
export class FeatureFlagService {

    ldClient: LDClient;
    flags: LDFlagSet;
    flagChange: Subject<Object> = new Subject<Object>();

    constructor(private env: EnvService, private auth: AuthService) { }

    async initialize() {

        const user = {
            key: this.auth.userId,
            name: this.auth.LoggedInUserEmail
        } as LDUser;

        this.ldClient = initialize(this.env.LD_KEY, user);
        await this.ldClient.waitForInitialization();
    }

    getFlagObservable(flagKey: string, defaultValue: boolean = DEFAULT_FLAG_VALUE): Observable<boolean> {

        const fetchFlag = new Subject<void>();

        this.ldClient.on(`${CHANGE_EVENT}:${flagKey}`, () => {

            fetchFlag.next();
        });

        this.ldClient.waitUntilReady().then(() => {

            fetchFlag.next();
        });

        return fetchFlag.pipe(
            map(() => {

                return this.ldClient.variation(flagKey, defaultValue);
            })
        );
    }

    unSubscribeFlagObservable(flagKey: string) {

        this.ldClient.off(`${CHANGE_EVENT}:${flagKey}`, () => { });
    }

    getFlagValue(flagKey: string, defaultValue: boolean = DEFAULT_FLAG_VALUE): boolean {

        return this.ldClient.variation(flagKey, defaultValue);
    }
}