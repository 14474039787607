import { Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth-service.service';

enum Constants {
  TIME_OUT_SECONDS = 10,
  MILISECONDS = 1000
};

export enum SessionBlockType {
  EXPIRED,
  BLOCKED,
  INACTIVE
}

/**Component to render a dialog to inactive module after timeout */
@Component({
  selector: 'session-block-alert',
  templateUrl: './session-block-alert.component.html',
  styleUrls: ['./session-block-alert.component.scss']
})
export class SessionBlockAlertComponent  {
  
  redirectTimeOut = 10;
  interval: any;
  type: SessionBlockType = SessionBlockType.EXPIRED;

  constructor(private _dialogRef: MatDialogRef<any>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private _auth:AuthService) {
    
    if (data && data.type) {

      this.type = data.type;
    }
    this.startTimeOut();
  }
  
  get TimeOut(){

    if(this.redirectTimeOut < 10){

      return `0${this.redirectTimeOut}`;
    }
    return this.redirectTimeOut;
  }

  goAccess(){
    
    clearInterval(this.interval);
    this._dialogRef.close();
    
    const closeSessionControl = this.type == SessionBlockType.EXPIRED;
    this._auth.logout(closeSessionControl);
  }

  startTimeOut(){
    
    this.redirectTimeOut = Constants.TIME_OUT_SECONDS;

    this.interval = setInterval(() => {
      
      if(this.redirectTimeOut == 0) {
          
        this.goAccess();
      } else {

        this.redirectTimeOut --;
      }
    },Constants.MILISECONDS)
  }
}