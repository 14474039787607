import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class AutomationFlowIndexedDBService {

    private dbName = 'AutomationFlowDB';
    private storeName = 'automationFlowData';
    private db: IDBDatabase | null = null;

    constructor() {

        this.connectDB();
    }

    private connectDB(): Promise<IDBDatabase> {

        let result: Promise<IDBDatabase>;

        if (this.db) {

            result = Promise.resolve(this.db);
        }
        else {

            result = new Promise((resolve, reject) => {

                const request = indexedDB.open(this.dbName, 1);

                request.onupgradeneeded = (event) => {
                    const db = (event.target as IDBOpenDBRequest).result;
                    if (!db.objectStoreNames.contains(this.storeName)) {
                        db.createObjectStore(this.storeName, { keyPath: 'key' });
                    }
                };

                request.onsuccess = () => {
                    this.db = request.result;
                    resolve(this.db);
                };

                request.onerror = () => {
                    reject(request.error);
                };
            });
        }

        return result;
    }

    async setItem(key: string, value: any): Promise<void> {
        const db = await this.connectDB();
        return new Promise((resolve, reject) => {
            const transaction = db.transaction(this.storeName, 'readwrite');
            const store = transaction.objectStore(this.storeName);
            value.key = key;
            const request = store.put(value);

            request.onsuccess = () => {
                resolve();
            };

            request.onerror = () => {
                reject(request.error);
            };
        });
    }

    async getAllItems(): Promise<any[]> {
        const db = await this.connectDB();

        return new Promise((resolve, reject) => {
            const transaction = db.transaction(this.storeName, 'readonly');
            const store = transaction.objectStore(this.storeName);
            const request = store.getAll();

            request.onsuccess = () => {
                resolve(request.result);
            };

            request.onerror = () => {
                reject(request.error);
            };
        });
    }

    async deleteOneItem(key: string): Promise<void> {
        const db = await this.connectDB();
        return new Promise((resolve, reject) => {
            const transaction = db.transaction(this.storeName, 'readwrite');
            const store = transaction.objectStore(this.storeName);
            const request = store.delete(key);

            request.onsuccess = () => {
                resolve();
            };

            request.onerror = () => {
                reject(request.error);
            };
        });
    }
}
