import { Injectable } from '@angular/core';
import mixpanel from 'mixpanel-browser';
import { EnvService } from 'src/app/core/services/env.service';

@Injectable({
  providedIn: 'root',
})
export class MixpanelService {

  constructor(private readonly environment: EnvService) {
    
    mixpanel.init(this.environment.MIXPANEL_TOKEN);
  }

  /**
   * Tracks an event using Mixpanel.
   * 
   * @param event - The name of the event to track.
   * @param properties - Optional properties associated with the event.
   */
  trackEvent(event: string, properties?: object) {

    mixpanel.track(event, properties);
  }
}
