import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';
import { FunctionsService } from '../../shared/services/functions.service';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { AuthService } from '../services/auth-service.service';
import { AlertService } from 'src/app/shared/services/alerts.service';
import { LoaderService } from 'src/app/shared/components/loader/loader.service';
import { EnvService } from '../services/env.service';
import { AutomationIconService } from 'src/app/shared/services/automation-icon.service';
import { AutomationFlowService } from 'src/app/shared/services/automation-flow.service';

// #region Constants

const BEARER = 'Bearer';
const AUTHORIZATION_KEY = 'Authorization';
const OCP_APIM_SUBSCRIPTION_KEY = 'Ocp-Apim-Subscription-Key';

// #endregion

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  
  private readonly HTTP_UNAUTHORIZED = 401;
  private readonly TIME_OUT_ALERTS:number = 10;
  
  constructor(private readonly _auth: AuthService, 
              private readonly functionsService: FunctionsService,
              private readonly _loader: LoaderService,
              private readonly router: Router,
              private readonly _alerts: AlertService,
              private readonly _env:EnvService,
              private readonly _automationIconService: AutomationIconService,
              private readonly _automationFlowSrv: AutomationFlowService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    if(this.isValidForInterceptor(req.url)){
      
      return this._auth.tokenValue().pipe(
        catchError(
          (error)=>{

            this.stopLoader();
            setTimeout(() => {

              this._alerts.error(error,true);
            }, this.TIME_OUT_ALERTS); //It is important to show the alert, because the navigateUrl kill the alerts
            
            this.goLogin();
            return throwError(error);
          }
        ),
        filter(token => token != null),
        take(1),
        switchMap(
          (token) => {
            
            const currentHeaders = req.headers.keys().reduce((headers, key) => {
              headers[key] = req.headers.get(key);

              return headers;
            }, {});

            const headers = new HttpHeaders({
              [AUTHORIZATION_KEY]: `${BEARER} ${ token }`,
              [OCP_APIM_SUBSCRIPTION_KEY]: this._env.SUBSCRIPTION_KEY,
              ...currentHeaders
            });

            const authReq = req.clone({ headers });
            return next.handle(authReq).pipe(
              tap(response => {

                // if automation is enabled handle the response
                if(this._automationIconService.automationStatus()) {

                  this.handleAutomationFlowResponse(req, response);
                }
              }),
              catchError((error: any) => {
                
                this.stopLoader();
                if (error instanceof HttpErrorResponse && error.status === this.HTTP_UNAUTHORIZED) {
                  
                  setTimeout(() => {
                    
                    this._auth.showAuthorizationError();
                  }, this.TIME_OUT_ALERTS); //It is important to show the alert, because the navigateUrl kill the alerts
                  
                  //this.goLogin(); is important remove to avoid redirect and show explcit error message
                }
                return throwError(error);
              })
            );
          }
        )
      );

    }else{

      return next.handle(req);
    }
    
  }

  private isValidForInterceptor(url:string):boolean{
    
    return  url.startsWith(this._env.BASE_URL);
  }

  private stopLoader(){

    this.functionsService.loader(false);
    this._loader.hide();
  }

  private goLogin(){

    this.router.navigateByUrl(this._env.OFFICE365.REDIRECT);;
  }

  private handleAutomationFlowResponse(req: HttpRequest<any>, response: HttpEvent<any>) {
    
    // attach query params to the url
    const urlWithParams = req.url + (req.params.keys().length > 0 ? '?' + req.params.keys().map(key => `${key}=${req.params.get(key)}`).join('&') : '');
    const validateRequestValid = this.validateUrl(urlWithParams);

    if (response instanceof HttpResponse && validateRequestValid.isValid) {

      const request = {
        description: validateRequestValid.methodName,
        method: req.method,
        url: response.url,
        status: response.status,
        headers: {[OCP_APIM_SUBSCRIPTION_KEY]: this._env.SUBSCRIPTION_KEY},
        bodyRequest: req.body || {},
        bodyResponse: response.body || {},
        date: new Date().toISOString(),
        instanceId: validateRequestValid.instanceId,
        containerId: validateRequestValid.containerId
      }

      this._automationFlowSrv.saveAutomationFlow(request);
    }
  }

  validateUrl(url: string) {

    let result = { isValid: false, methodName: '', instanceId: '', containerId: '' };

    const urlPatterns: { condition: (url: string) => boolean; methodName: string }[] = [
      { condition: (url) => url.endsWith('instances'), methodName: 'Create Instance' },
      { condition: (url) => url.includes('partial-save'), methodName: 'Partial Save' },
      { condition: (url) => url.endsWith('process'), methodName: 'Process Instance' },
      { condition: (url) => url.endsWith('executePartialFunction'), methodName: 'Partial Function' },
      { condition: (url) => url.includes('isInbox') && url.includes('containers'), methodName: 'Get Container' },
      { condition: (url) => url.endsWith('inboxAssignment'), methodName: 'Take Instance From Inbox' },
    ];

    const instanceContainerRegex = /instances\/([a-fA-F0-9-]+)\/containers\/([a-fA-F0-9-]+)/;

    for (const pattern of urlPatterns) {

      if (pattern.condition(url)) {
        
        result = { isValid: true, methodName: pattern.methodName, instanceId: '', containerId: '' };

        // if the url is a partial save or process instance, we need to extract the instanceId and containerId
        if (pattern.methodName === 'Partial Save' || pattern.methodName === 'Process Instance') {

          const instanceContainerMatch = url.match(instanceContainerRegex);

          result = {
            ...result,
            instanceId: instanceContainerMatch[1],
            containerId: instanceContainerMatch[2]
          };
        }
      }
    }

    return result;
  }
}
