import { Injectable } from "@angular/core";
import { AutomationFlowIndexedDBService } from "./automation-flow-indexedDB.service";

const PARTIAL_SAVE_KEY = 'Partial Save';
const PROCESS_INSTANCE_KEY = 'Process Instance';

@Injectable({
    providedIn: 'root'
})
export class AutomationFlowService{

    constructor(private _indexDBService: AutomationFlowIndexedDBService) {}

    async saveAutomationFlow(automationFlow: any) {

        const data = await this.prepareDataToSave(automationFlow);

        this.writeData(data);
    }

    async prepareDataToSave(flowToSave: any) {

        const allItems = await this._indexDBService.getAllItems();
        let latestAutomationFlow = [flowToSave];

        if (allItems.length > 0) {

            const orderByDate = allItems.sort((a: any, b: any) => a.date - b.date);

            // get the last item to check if it is a partial save or process instance
            const lastItem = orderByDate[orderByDate.length - 1];

            //if last item is a partial save and belong to the same instance and container, we need to merge bodyRequest 
            if (lastItem && lastItem.description === PARTIAL_SAVE_KEY && lastItem.instanceId === flowToSave.instanceId && lastItem.containerId === flowToSave.containerId && flowToSave.description === PARTIAL_SAVE_KEY) {

                // merge bodyRequest of the last item with the new one
                const partialSaves = [lastItem, flowToSave];
                const mergedBodyRequest = partialSaves.reduce((acc, current) => ({ ...acc, ...current.bodyRequest }), {});

                // delete the last item in indexDB to conserve the new one with the merged bodyRequest
                await this._indexDBService.deleteOneItem(lastItem.date + lastItem.description);

                latestAutomationFlow = [{ ...flowToSave, bodyRequest: mergedBodyRequest }];

            }
            else if (lastItem.description === PROCESS_INSTANCE_KEY && flowToSave.description === PARTIAL_SAVE_KEY) {

                // is a new partial save, after a process instance, we need to keep the last item and add the new one
                orderByDate.push(flowToSave);
            }
        }

        return latestAutomationFlow;
    }

    async writeData(data: any) {

        // set item one by one to avoid the issue with the same key and big data
        for (const item of data) {

            // we need to add the date and description to the key to avoid the same key
            const key = item.date + item.description;

            await this._indexDBService.setItem(key, item);
        }
    }
}