export enum FeatureFlagEnum {

  AAD_SYNC_AGENTS = 'web-aad-sync-agents',
  SESSION_VERIFICATION = 'web-auth-session-verification',
  FOLDER_ALLOW_PARALLEL_UPDATE = 'web-folder-allow-parallel-update',
  AGENT_FOLDER_INFO_API = 'web-agent-folder-info-api',
  PARTIAL_SAVE_API = 'web-partial-save-api',
  DIGITAL_ONBOARDING_ADMIN_PANEL = 'web-digital-onboarding-admin-panel',
  REQUIRED_HINT = 'web-required-hint',
  INSTANCES_REASSIGNMENT = 'web-instances-reassignment',
  PRODUCTS_FORMS_ADMIN = 'web-products-forms-admin',
  AUTOMATION_MODE = 'ninja-project'
}
