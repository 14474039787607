import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { User } from '../../shared/models';
import { Observable, of, throwError } from 'rxjs';
import { ProfileService } from '../../shared/services/profile.service';
import { catchError, tap } from 'rxjs/operators';
import { MatDialog } from '@angular/material';
import { SessionBlockAlertComponent, SessionBlockType } from 'src/app/shared/components/session-block-alert/session-block-alert.component';
import { UserInfoService } from '../services/user-info.service';

// #region Constants

const SESSION_INACTIVE = {
  width: '515px',
  height: '265px',
  backdropClass: 'session-block-backdrop',
};

const HTTP_FORBIDDEN = 403;

// #endregion

@Injectable()
export class ProfileResolverService implements Resolve<User> {

  constructor(private readonly _profileSrv: ProfileService,
    private readonly _userInfo: UserInfoService,
    private readonly dialog: MatDialog) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<User> {

    return this._profileSrv.getUser().pipe(
      tap((user: User) => {
        this._userInfo.userProfile$.next(user);
      }),
      catchError((error) => {

        if (error.status === HTTP_FORBIDDEN) {
          
          this.dialog.open(SessionBlockAlertComponent, {
            width: SESSION_INACTIVE.width,
            height: SESSION_INACTIVE.height,
            backdropClass: SESSION_INACTIVE.backdropClass,
            data: {
              type: SessionBlockType.INACTIVE,
            },
            disableClose: true,
          });

          // Return a null or default User object when there's a 403 error
          return of(null as User);
        }
        else {
          // If theres a different error, throw the error to be handled globally
          return throwError(() => error);
        }
      })
    );
  }
}
