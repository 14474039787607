import { ApplicationInsightsErrorHandler } from './shared/utils/applicationInsightsErrorHandler';
import { Inject, NgModule, ErrorHandler } from '@angular/core';
// Modules
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslatorModule } from './core/translator.module';
// Authentication
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// Componentes
import { AppComponent } from './app.component';
import { MaterialModule } from 'src/app/core/material.module';
import { LoginModule } from './modules/login/login.module';
import { SharedModule } from './shared/shared.module';
import { NotificationsComponent, PopupComponent } from './shared';
import { DatePipe, registerLocaleData } from '@angular/common';
import localeEsMx from '@angular/common/locales/es-MX';
import { MatPaginatorIntl } from '@angular/material';
import { CommentDialogComponent } from './shared/components/pop-up/comment-dialog/comment-dialog.component';
import { MatPaginatorIntlCro } from './shared/services/mat-paginator-intl.service';
import { CoreModule } from './core/core.module';
import { MonacoEditorModule } from 'ngx-monaco-editor';
import { EnvServiceProvider } from './core/providers/env.service.provider';
import { getMsalConfig } from './configs/utils.config';
import { NgxMaskModule } from './ngx-mask';
import { InsightsModule } from './modules/insights/insights.module';
import { EngineLocales, MOMENT_LOCALES } from './shared/tokens/moment-locals.token';
import * as moment from 'moment';
import { HttpClientModule } from '@angular/common/http';
import { MsalModule } from '@azure/msal-angular';
import { AutomationFlowButtonComponent } from './shared/components/automation-flow-button/automation-flow-button.component';

registerLocaleData(localeEsMx);

@NgModule({
  declarations: [
    AppComponent,
    NotificationsComponent,
    PopupComponent,
    CommentDialogComponent
  ],
  exports: [
    AutomationFlowButtonComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    HttpClientModule,
    MonacoEditorModule.forRoot(),
    NgxMaskModule.forRoot(),
    TranslatorModule,
    LoginModule,
    InsightsModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    MsalModule.forRoot(getMsalConfig().mainConfiguration,getMsalConfig().projectConfiguration),
    SharedModule.forRoot()
  ],
  providers: [
    EnvServiceProvider,
    {
      provide: MatPaginatorIntl,
      useClass: MatPaginatorIntlCro
    },
    DatePipe,
    {
      provide: ErrorHandler,
      useClass: ApplicationInsightsErrorHandler
    },
    AutomationFlowButtonComponent
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    NotificationsComponent,
    PopupComponent,
    CommentDialogComponent
  ]
})
export class AppModule {
  constructor(@Inject(MOMENT_LOCALES) private readonly _locales: EngineLocales) {
    moment.updateLocale('es', _locales.sp);
  }
}
