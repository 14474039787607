import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { MsalService } from '@azure/msal-angular';
import { AuthResponse, InteractionRequiredAuthError } from 'msal';
import { from, Observable } from 'rxjs';
import { AuthErrorAlertComponent } from 'src/app/shared/components/auth-error-alert/auth-error-alert.component';
import { LoaderService } from 'src/app/shared/components/loader/loader.service';
import { AlertService } from 'src/app/shared/services/alerts.service';
import { AuthAccount, AuthService } from './auth-service.service';
import { EnvService } from './env.service';
import { SessionManagerService } from 'src/app/shared/services/session-manager.service';
import { FeatureFlagService } from 'src/app/shared/services/feature-flag.service';
import { FeatureFlagEnum } from 'src/app/shared/enums/featureFlag.enum';

const ERROR_DIALOG_ID:string = 'auth-error-dialog';
const ALERT_TIME_OUT:number = 1000;
const SESSION_IDENTIFIER_STORAGE_KEY = 'sessionIdentifier';
const BASE_36_RADIX = 36;
const BASE_36_START = 2;

@Injectable()
export class AuthServiceMsal implements AuthService {
   
  constructor(protected readonly _authProvider: MsalService,
              private readonly _dialog: MatDialog,
              private readonly _environment: EnvService,
              private readonly _alerts: AlertService,
              private readonly _loading:LoaderService,
              private readonly sessionManager: SessionManagerService,
              private readonly featureFlag: FeatureFlagService
              ) {
  }

  loginListener(callback): void {
    
    this._authProvider.handleRedirectCallback((authError, response) => {
      
      if (authError) {
        console.error('Redirect Error: ', authError.errorMessage);
      }else{
       
        //Added some extra actions here
        callback(this.userInfo.userName || this.userInfo.name); //Execute callback event pass email or user name
      }
    });
  }
  

  login(): void{

    this._authProvider.loginRedirect();
  }

  async logout(closeSessionControl:boolean) {

    if (closeSessionControl && this.featureFlag.getFlagValue(FeatureFlagEnum.SESSION_VERIFICATION)) {

      await this.sessionManager.closeSession().toPromise();
    }

    this._authProvider.logout();
  }

  get userInfo(): AuthAccount {
    
    const msalUser = this._authProvider.getAccount();
    if(msalUser.idTokenClaims.hasOwnProperty('emails') && msalUser.idTokenClaims.emails && msalUser.idTokenClaims.emails.length > 0){

      msalUser.userName = msalUser.idTokenClaims.emails[0];
    }
    return {
      uid: msalUser ? msalUser.accountIdentifier : '',
      userName: msalUser ? msalUser.userName : '',
      name: msalUser ? msalUser.name : ''
    };
  }

  get userId(): string {
    
    return this._authProvider.getAccount().accountIdentifier;
  }

  get accessToken(): Promise<string> {
    
    const request = {
      scopes: this._environment.MSAL.SCOPES
    };

    //If not loading show loader
    const showLoader = !this._loading.state.value || !this._loading.state.value.state;
    if(showLoader){

      this._loading.show('Cargando...');
    }
    
    return new Promise<string>(
      (resolve,reject)=>{

        this._authProvider.acquireTokenSilent(request).then(
          (result:AuthResponse)=>{

            if(showLoader){

              this._loading.hide();
            }
            //Update to send RawIdToken in place of accesToken
            resolve(result.idToken.rawIdToken);
          },
          async (reason: any)=>{

            if(reason instanceof InteractionRequiredAuthError){

              if(this._loading.state.value.state){ //If loader is visible, then hide it

                this._loading.hide();
              }

              this._alerts.error('Su sesión ha expirado. Para continuar debe autenticarse.')
              await new Promise(resolve => setTimeout(resolve, ALERT_TIME_OUT)); 
              return this._authProvider.logout();
            }
            reject(reason);
          }
        )
      }
    );
   
  }

  get isAuthenticated(): boolean {

    return this._authProvider.getAccount() != null;
  }

  get  LoggedInUserEmail(): string{

    return this._authProvider.getAccount().userName;
  }
  
  public tokenValue():Observable<string>{
    
    return from(this.accessToken);
  }
  
  public showAuthorizationError():void{
    
    const currentDialog = this._dialog.getDialogById(ERROR_DIALOG_ID);
    if(!currentDialog){ //To avoid overload modals

      this._dialog.open(AuthErrorAlertComponent,{
        id: ERROR_DIALOG_ID,
        width: '515px',
        height: '280px',
        disableClose: true,
        hasBackdrop:true
      });
    }
  }

  /**
   * Current method clear all keys of adal in actual localStorage to avoid conflicts with msal
  */
  public clearLocalStorage():void {

    const adalKey = 'adal';

    Object.keys(localStorage).forEach(
      (option: string) => {

        if(option.indexOf(adalKey) >= 0){

          localStorage.removeItem(option);
        }
      }
    );
  }

  get sessionIdentifier(): string {
    
    let result = localStorage.getItem(SESSION_IDENTIFIER_STORAGE_KEY);

    if(!result){

      // generate a random string to identify the session
      result = Math.random().toString(BASE_36_RADIX).slice(BASE_36_START);

      localStorage.setItem(SESSION_IDENTIFIER_STORAGE_KEY,result);
    }

    return result;
  }
}
