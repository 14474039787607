import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Application, Company, Notification, User } from '../models';
import { APPLICATIONS, COMPANY, NOTIFICATIONS } from '../mocks';
import { URLS_SETTINGS } from '../../configs/urls.config';
import { map, switchMap, tap } from 'rxjs/operators';
import { BaseService } from '../../core/services/base-service.service';

// #region Constants

const PROFILE_LOADER_MESSAGE = 'Cargando perfil del usuario...';

// #endregion

@Injectable({
  providedIn: 'root'
})
export class ProfileService extends BaseService {
  userProfile$ = new BehaviorSubject<User>(undefined);
  userProfile: User;
  URLS = URLS_SETTINGS;
  applications: Application[] = [];

  getCompany(): Observable<Company> {
    return of(JSON.parse(JSON.stringify(COMPANY)));
  }

  getUser(agentId: string = this._auth.userInfo.uid): Observable<any> {

    const request = this.get(`organizations/${this._env.APIS.TENANTID}/agents/${agentId}/permissions`, {
      baseUrl: this._env.APIS.TENANTS,
      loader: true,
      loaderMessage: PROFILE_LOADER_MESSAGE
    }).pipe(
      map((response: any) => {
        return {
          id: agentId,
          ...response,
          permissions: {
            permissions: response.permissions,
          }
        } as User;
      })
    );

    return request.pipe(
      switchMap(user => this.get('', { baseUrl: window['__env'].APIS.PARTIAL_SAVE_PERMISSION }).pipe(
        map((res: any) => {
          return { ...user, originations: { denyPartialSave: !!res.result } } as User;
        })
      )),
      tap(user => { 
        if (window['__env'].FULLSTORY) {
          window['userFSId'] = user.id;
          window['userFSDisplayName'] = user.name;
          window['userFSEmail'] = this._auth.LoggedInUserEmail;
          window['FS'].identify(window['userFSId'], {
            displayName: window['userFSDisplayName'],
            email: window['userFSEmail'],
            reviewsWritten_int: 14
          });
        }
      }),
      tap(user => this.userProfile$.next(user))
    );
  }


  getApplications(): Observable<Application[]> {
    return of(JSON.parse(JSON.stringify(APPLICATIONS)));
  }

  getNotifications(): Observable<Notification[]> {
    return of(JSON.parse(JSON.stringify(NOTIFICATIONS)));
  }
}
