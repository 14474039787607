import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../core/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { AgmCoreModule } from '@agm/core';
import { PipesModule } from './pipes/pipes.module';
import { APP_SETTINGS } from '../configs/app-settings.config';
import { MatLinkPreviewModule } from '@angular-material-extensions/link-preview';
import { MatFileUploadModule } from 'angular-material-fileupload';
import { ImageViewerModule } from '@hallysonh/ngx-imageviewer';
import { MonacoEditorModule } from 'ngx-monaco-editor';
import { MatTableExporterModule } from 'mat-table-exporter';
import { ErrorStateMatcher, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { MyErrorStateMatcher } from '../modules/form/custom-state-matcher';
import { ClickOutSideDirective } from './directives/clickOutSide.directive';
import { ConfirmationModalButtonCustomComponent } from '../modules/form/components/form-alert/modal-confirmation-button-custom/confirmation-modal-button-custom.component';
import { JavascriptEditorComponent } from './components/js-editor/javascript-editor.component';
import { ModalBaseComponent } from '../modules/form/components/form-alert/modal-base/modal-base.component';
import { ConfirmationModalComponent } from '../modules/form/components/form-alert/modal-confirmation/confirmation-modal.component';
import { DisableControlDirective } from './directives/disable-control.directive';
import { FileDropDirective } from './directives/file-drop.directive';
import { DynamicTemplateDirective } from './directives/dynamic-tab';
import { TableHeaderComponent } from './components/tables/table-header/table-header.component';
import { FooterCatalogsComponent } from './components/footer-catalogs/footer-catalogs.component';
import { SuccessModalComponent } from '../modules/form/components/form-alert/success-modal.component';
import { ErrorModalComponent } from '../modules/form/components/form-alert/modal-warning/error-modal.component';
import { AlertComponent } from './components/alerts/alerts.component';
import { DialogPreviewIframeComponent } from '../modules/form/components/form-iframe/components/dialog-preview-iframe/dialog-preview-iframe.component';
import { MainCardComponent } from './components/main-card/main-card.component';
import { CustomTitlePipe } from './pipes/custom-title.pipe';
import { AdministrationNavbarComponent } from './components/administration-navbar/administration-navbar.component';
import { CustomTableComponent } from './components/custom-table/custom-table.component';
import { CustomColumnComponent } from './components/custom-table/custom-column/custom-column.component';
import { ImageLoaderFullscreenComponent } from './components/image-loader/image-loader-fullscreen/image-loader-fullscreen.component';
import { NavbarSearchComponent } from './components/administration-navbar/navbar-search/navbar-search.component';
import { CustomButtonComponent } from './components/custom-button/custom-button.component';
import { AplicationListComponent } from './components/aplication-list/aplication-list.component';
import { MainMenuComponent } from './components/menu-list/menu-list.component';
import { NavbarAdministrationTitleComponent } from './components/navbar-administration-title/navbar-administration-title.component';
import { CustomSideElementComponent } from '../modules/administration/components/custom-side-element/custom-side-element.component';
import { CustomSideCardComponent } from '../modules/administration/components/custom-side-card/custom-side-card.component';
import { CustomSideCardIconsComponent } from '../modules/administration/components/custom-side-card-icons/custom-side-card-icons.component';
import { TabContentComponent } from './components/tab-content/tab-content.component';
import { TabGroupComponent } from './components/tab-group/tab-group.component';
import { FoldersBrowserComponent } from './components/folders-browser/folders-browser.component';
import { FolderBrowserItemComponent } from './components/folder-browser-item/folder-browser-item.component';
import { FolderItemResultComponent } from './components/folder-item-result/folder-item-result.component';
import { ProductBrowserItemComponent } from './components/product-browser-item/product-browser-item.component';
import { ProductBrowserItemResultComponent } from './components/product-browser-item-result/product-browser-item-result.component';
import { CustomChipsComponent } from './components/custom-chips/custom-chips.component';
import { BrowserComponent } from './components/browser/browser.component';
import { ChannelPipe } from './components/browser/channel.pipe';
import { CustomAccordionComponent } from './components/custom-accordion/custom-accordion.component';
import { CrBrowserComponent } from './components/cr-browser/cr-browser.component';
import { CustomWizardModalComponent } from './components/custom-wizard-modal/custom-wizard-modal.component';
import { CustomWizardStepComponent } from './components/custom-wizard-modal/custom-wizard-step/custom-wizard-step.component';
import { CustomProductPublisherModalComponent } from './components/custom-product-publisher-modal/custom-product-publisher-modal.component';
import { CustomProductInfoComponent } from './components/custom-product-info/custom-product-info.component';
import { CustomProductInfoModalComponent } from './components/custom-product-info-modal/custom-product-info-modal.component';
import { CustomWizardButtonsComponent } from './components/custom-wizard-buttons/custom-wizard-buttons.component';
import { IconPipe } from './components/folder-item-result/icon.pipe';
import { CustomOneInputFieldModalComponent } from './components/custom-oneInputField-modal/custom-oneInputField-modal.component';
import { HierarchyModalComponent } from './components/hierarchy-modal/hierarchy-modal.component';
import { ExpansiveRowComponent } from './components/expansive-row/expansive-row.component';
import { FolderRowComponent } from './components/folder-row/folder-row.component';
import { FolderFinderComponent } from './components/folder-finder/folder-finder.component';
import { FormHelperComponent } from '../modules/form/components/form-helper/form-helper.component';
import { CustomFileUploadComponent } from '../modules/form/components/custom-file-upload/custom-file-upload.component';
import { RowBaseComponent } from './components/row-base/row-base.component';
import { GenericRowComponent } from './components/generic-row/generic-row.component';
import { LoaderContainerDirective } from './directives/loader.directive';
import { BlockingLoaderComponent } from './components/loader/blocking/blocking-loader.component';
import { NonBlockingLoaderComponent } from './components/loader/nonBlocking/non-blocking-loader.component';
import { LoaderService } from './components/loader/loader.service';
import { NonBlockingModalComponent } from './components/loader/nonBlockingModal/nonBlockingModal.component';
import { CommonLoaderDirective } from './components/loader/CustomLoader.class';
import { FooterMainCardButtonsComponent } from './components/footer-main-card-buttons/footer-main-card-buttons.component';
import { BulkLoadRowComponent } from './components/folder-finder/bulk-load-row/bulk-load-row.component';
import { HelpDeskButtonComponent } from './components/help-desk-button/help-desk-button.component';
import { FormUtilitiesService } from './services/form-utilities.service';
import { AdministrationNavbarV2Component } from './components/administration-navbar/administration-navbar-v2.component';
import { MainCardV2Component } from './components/main-card-v2/main-card-v2.component';
import { CatalogCrudContainerComponent } from './components/catalog-crud-container/catalog-crud-container.component';
import { CustomTableV2Component } from './components/custom-table-v2/custom-table-v2.component';
import { CdkTableModule } from '@angular/cdk/table';
import { SearchModalComponent } from './components/administration-navbar/search-modal/search-modal.component';
import { ResultTextLabelComponent } from './components/result-text-label/result-text-label.component';
import { FormFileUploaderSharedService } from './services/form-file-uploader-shared.service';
import { CustomBreadcrumTitleComponent } from './components/custom-breadcrum-title/custom-breadcrum-title.component';
import { CustomChipsV2Component } from './components/custom-chips-v2/custom-chips-v2.component';
import { CustomBodyContainerComponent } from './components/custom-body-container/custom-body-container.component';
import { TableMenuComponent } from './components/table-menu/table-menu.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { CustomCollapsableTableComponent, SelectableCheckboxComponent } from './components/custom-table-v2';
import { NotificationsService } from './services/notifications.service';
import { LocalesProvider } from './tokens/moment-locals.token';
import { BaseNavbarComponent } from './components/navbars/base/base.component';
import { NotificationButtonComponent } from './components/notification-button/notification-button.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HeaderInterceptor } from '../core/authentication/headerinterceptor';
import { InputDateRangeComponent } from './components/input-date-range/input-date-range.component';
import { InputCustomSelectComponent } from './components/input-custom-select/input-custom-select.component';
import { MgChartComponent } from './components/mg-chart/mg-chart.component';
import { ChartSolicitudeLifeCycleComponent } from './components/chart-solicitude-life-cycle/chart-solicitude-life-cycle.component';
import { AuthService } from '../core/services/auth-service.service';
import { SurveyComponent } from './components/survey/survey.component';
import { AuthErrorAlertComponent } from './components/auth-error-alert/auth-error-alert.component';
import { InputTreeSelectComponent } from './components/input-tree-select/input-tree-select.component';
import { SessionBlockAlertComponent } from './components/session-block-alert/session-block-alert.component';
import { AuthServiceMsal } from '../core/services/auth-service-msal.service';
import { EngineMaskDirective } from './engine-mask/engine-mask.directive';
import { NotificationsPanelComponent } from './components/notification-button/notifications-panel/notifications-panel.component';
import { NotificationItemComponent } from './components/notification-button/notification-item/notification-item.component';
import { NotificationAlertComponent } from './components/notification-alert/notification-alert.component';
import { DynamicHeightDirective } from './directives/dynamic-height.directive';
import { MarkdownPipe } from './pipes/markdown';
import { InputRichTextEditorComponent } from './components/input-richtext-editor/input-richtext-editor.component';
import { InstanceHistoryReportComponent } from './components/instance-history-report/instance-history-report.component';
import { FeatureFlagComponent } from './components/feature-flag/feature-flag.component';
import { AppChartComponent } from './components/app-chart/app-chart.component';
import { DateRangeWidgetComponent } from './components/date-range-widget/date-range-widget.component';
import { FeatureFlagDirective } from './directives/feature-flag.directive';
import { CustomWebElementWrapperComponent } from './components/custom-element-wrapper/custom-element-wrapper.component';
import { AutomationFlowButtonComponent } from './components/automation-flow-button/automation-flow-button.component';

const DD_MM_YYYY_Format = {
  parse: {
    dateInput: 'DD/MM/YYYY'
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule,
    ReactiveFormsModule,
    PipesModule,
    FormsModule,
    AgmCoreModule.forRoot({
      apiKey: APP_SETTINGS.MAPS.GOOGLE_MAPS.API_KEY
    }),
    RouterModule,
    MatLinkPreviewModule.forRoot(),
    MatFileUploadModule,
    ImageViewerModule,
    MonacoEditorModule,
    MatTableExporterModule,
    CdkTableModule,
    NgxMatSelectSearchModule
  ],
  providers: [
    CurrencyPipe,
    { provide: ErrorStateMatcher, useClass: MyErrorStateMatcher },
    { provide: MAT_DATE_FORMATS, useValue: DD_MM_YYYY_Format },
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
    FormUtilitiesService,
    FormFileUploaderSharedService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeaderInterceptor,
      multi: true
    },
    AuthServiceMsal,
    {
      provide: AuthService, 
      useExisting: AuthServiceMsal
    },
    { //It is important to can mock window object on unit testing
      provide: 'Window', 
      useValue: window 
    },
  ],
  declarations: [
    BaseNavbarComponent,
    ClickOutSideDirective,
    JavascriptEditorComponent,
    ModalBaseComponent,
    ConfirmationModalComponent,
    ConfirmationModalButtonCustomComponent,
    DisableControlDirective,
    FileDropDirective,
    DynamicTemplateDirective,
    TableHeaderComponent,
    FooterCatalogsComponent,
    SuccessModalComponent,
    ErrorModalComponent,
    AlertComponent,
    DialogPreviewIframeComponent,
    BlockingLoaderComponent,
    MainCardComponent,
    CustomTitlePipe,
    AdministrationNavbarComponent,
    CustomTableComponent,
    CustomColumnComponent,
    ImageLoaderFullscreenComponent,
    NavbarSearchComponent,
    CustomButtonComponent,
    FooterMainCardButtonsComponent,
    AplicationListComponent,
    MainMenuComponent,
    NavbarAdministrationTitleComponent,
    CustomSideElementComponent,
    CustomSideCardComponent,
    CustomSideCardIconsComponent,
    TabContentComponent,
    TabGroupComponent,
    FoldersBrowserComponent,
    FolderBrowserItemComponent,
    FolderItemResultComponent,
    ProductBrowserItemComponent,
    ProductBrowserItemResultComponent,
    CustomChipsComponent,
    BrowserComponent,
    ChannelPipe,
    CustomAccordionComponent,
    CrBrowserComponent,
    CustomWizardModalComponent,
    CustomWizardStepComponent,
    CustomProductPublisherModalComponent,
    CustomProductInfoComponent,
    CustomProductInfoModalComponent,
    CustomAccordionComponent,
    CustomWizardButtonsComponent,
    CustomOneInputFieldModalComponent,
    IconPipe,
    HierarchyModalComponent,
    ExpansiveRowComponent,
    FolderRowComponent,
    FolderFinderComponent,
    FormHelperComponent,
    CustomFileUploadComponent,
    RowBaseComponent,
    GenericRowComponent,
    BulkLoadRowComponent,
    NonBlockingLoaderComponent,
    NonBlockingModalComponent,
    LoaderContainerDirective,
    CommonLoaderDirective,
    HelpDeskButtonComponent,
    AdministrationNavbarV2Component,
    MainCardV2Component,
    CatalogCrudContainerComponent,
    CustomTableV2Component,
    CustomCollapsableTableComponent,
    SearchModalComponent,
    ResultTextLabelComponent,
    CustomBreadcrumTitleComponent,
    CustomChipsV2Component,
    CustomBodyContainerComponent,
    TableMenuComponent,
    SelectableCheckboxComponent,
    NotificationButtonComponent,
    InputDateRangeComponent, // Date range component to using with reactive forms
    InputCustomSelectComponent, // Dropdown select component to using with reactive forms
    MgChartComponent,
    ChartSolicitudeLifeCycleComponent,
    SurveyComponent,
    AuthErrorAlertComponent, //Modal to show Authorization errors 
    InputTreeSelectComponent,
    SessionBlockAlertComponent, //Modal to show block session alert 
    EngineMaskDirective, //New directive to handle engine masks
    NotificationsPanelComponent, //New components to render notifications panel
    NotificationItemComponent,
    NotificationAlertComponent, //Component to render alert with new notifications
    DynamicHeightDirective, //Directive to update Hight of element based on window height
    MarkdownPipe,
    InputRichTextEditorComponent,
    InstanceHistoryReportComponent,
    FeatureFlagComponent,
    AppChartComponent,
    DateRangeWidgetComponent,
    FeatureFlagDirective,
    CustomWebElementWrapperComponent,
    AutomationFlowButtonComponent
  ],
  exports: [
    BaseNavbarComponent,
    ClickOutSideDirective,
    JavascriptEditorComponent,
    TableHeaderComponent,
    FooterCatalogsComponent,
    SuccessModalComponent,
    ErrorModalComponent,
    ModalBaseComponent,
    ConfirmationModalComponent,
    ConfirmationModalButtonCustomComponent,
    AlertComponent,
    BlockingLoaderComponent,
    MainCardComponent,
    CustomTitlePipe,
    PipesModule,
    AdministrationNavbarComponent,
    CustomTableComponent,
    CustomColumnComponent,
    NavbarSearchComponent,
    CustomButtonComponent,
    FooterMainCardButtonsComponent,
    AplicationListComponent,
    MainMenuComponent,
    NavbarAdministrationTitleComponent,
    FileDropDirective,
    DynamicTemplateDirective,
    CustomSideElementComponent,
    CustomSideCardComponent,
    CustomSideCardIconsComponent,
    DisableControlDirective,
    TabContentComponent,
    TabGroupComponent,
    FoldersBrowserComponent,
    FolderBrowserItemComponent,
    FolderItemResultComponent,
    ProductBrowserItemResultComponent,
    CustomChipsComponent,
    CustomProductPublisherModalComponent,
    CustomProductInfoModalComponent,
    CustomWizardModalComponent,
    CustomWizardStepComponent,
    BrowserComponent,
    CrBrowserComponent,
    CustomAccordionComponent,
    CustomWizardButtonsComponent,
    CustomOneInputFieldModalComponent,
    ExpansiveRowComponent,
    FolderRowComponent,
    FolderFinderComponent,
    FormHelperComponent,
    CustomFileUploadComponent,
    RowBaseComponent,
    GenericRowComponent,
    BulkLoadRowComponent,
    NonBlockingLoaderComponent,
    NonBlockingModalComponent,
    LoaderContainerDirective,
    HelpDeskButtonComponent,
    AdministrationNavbarV2Component,
    MainCardV2Component,
    CatalogCrudContainerComponent,
    CustomTableV2Component,
    CustomCollapsableTableComponent,
    ResultTextLabelComponent,
    CustomBreadcrumTitleComponent,
    CustomChipsV2Component,
    CustomBodyContainerComponent,
    TableMenuComponent,
    NgxMatSelectSearchModule,
    TranslateModule,
    SelectableCheckboxComponent,
    NotificationButtonComponent,
    InputDateRangeComponent,
    InputCustomSelectComponent,
    MgChartComponent,
    ChartSolicitudeLifeCycleComponent,
    SurveyComponent,
    AuthErrorAlertComponent,
    InputTreeSelectComponent,
    SessionBlockAlertComponent,
    EngineMaskDirective,
    NotificationsPanelComponent, //New components to render notifications panel
    NotificationItemComponent,
    NotificationAlertComponent,
    DynamicHeightDirective,
    MarkdownPipe,
    InputRichTextEditorComponent,
    InstanceHistoryReportComponent,
    FeatureFlagComponent,
    AppChartComponent,
    DateRangeWidgetComponent,
    FeatureFlagDirective,
    CustomWebElementWrapperComponent,
    AutomationFlowButtonComponent
  ],
  entryComponents: [DialogPreviewIframeComponent, SuccessModalComponent, ConfirmationModalComponent, NonBlockingLoaderComponent,
    ConfirmationModalButtonCustomComponent, ErrorModalComponent, ImageLoaderFullscreenComponent, SearchModalComponent,
    CustomProductPublisherModalComponent, CustomProductInfoModalComponent, CustomOneInputFieldModalComponent, HierarchyModalComponent,
    AuthErrorAlertComponent,SessionBlockAlertComponent]
})
export class SharedModule {
  static forRoot() {
    return {
      ngModule: SharedModule,
      providers: [LoaderService, NotificationsService, LocalesProvider]
    };
  }
}
